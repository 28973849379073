.item {
  &__img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }

  &__btn {
    margin-top: 2rem;
    text-align: center;
  }
}
@primary-color: #CF1515;