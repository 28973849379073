*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  font-size: 1rem !important;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 1.3rem;
}


a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

.container {
  margin-top: 0.2rem;
}

.box {
  border: 1px solid #e2e2e2;
  padding: 1rem;
}

.box:not(:last-child) {
  margin-bottom: 1rem;
}

.box-title {
  /* color: #CF1515; */
  color: black;
}

