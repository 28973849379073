
.imgContainer {  
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-position: center center;
}
.imgContainer:hover{ 
  cursor: pointer;
  opacity: 0.6;
}

.imgContainer1 {  //all users imgs
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-position: center center;
}

.imgContent {
  background-color: #fff;
  padding: 20px 10px;
  font-family: serif;
  color: rgb(255, 72, 0);
  font-weight: 600;
  text-align: center;
 
}
.greenText {
  color: green;
}


.header {
  // position: sticky;
  z-index: 100;
  width: 100%;
  top: 0;
  background: #fff !important;
  border-bottom: solid rgb(223, 221, 221) 1px;
  height: 70px !important;
  display: flex;
  align-items: center;
  justify-content:  flex-end;
}

.sider {
  // height: 100vh !important;
  background-color: #fff !important;
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
  
  &__menu {
    height: 100% !important;
    border-right: 0 !important;
    background-color: rgb(250, 250, 250) !important;

  }
}

.content {
  padding: 24px;
  padding-top: 40px;
  background-color: #fff;
}

@primary-color: #CF1515;