.masterclass {
  &__heading {
    // font-size: 1.1rem;
    // text-decoration: underline;
  }

  &__container {
    // margin-left: 1rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__title {
    // font-size: 1rem;
    color: rgb(210, 57, 57);
  }
  &__greenText {
    color: green!important;
  }

  &__list {
    list-style-type: disc;
    padding-left: 2rem;
  }
}

.schrodinger {
  &__case {
    &__title {
      // font-size: 1rem;
      color: rgb(210, 57, 57);
    }
    &__greenText {
      color: green!important;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.trinity {
  &__lesson {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.resources {
  display: flex;
  flex-direction: column;
}

.playerWrapper {
  position: relative;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  height: 60vh;
  min-height: 300px;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.gap {
  content: ' ';
  border-top: 1px solid #e2e2e2;
  margin-bottom: 1rem;
}


.item {
  &__img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }


  &__btn {
    margin-top: 2rem;
    text-align: center;
  }
}
@primary-color: #CF1515;