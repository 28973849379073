.item {
  &__img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }

  &__btn {
    margin-top: 2rem;
    text-align: center;
  }
}

.projects {
  margin-top: 1rem;
}

.project {
  margin-top: 2rem;

  &__problem {
    display: inline-block;
  }
}
@primary-color: #CF1515;