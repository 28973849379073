
.wrapper {
  background-color: rgb(241, 241, 241);
  position: relative;
}

.closeIcon {
  width: 20px;
  position: fixed;
  right: 50px;
  top: 50px;
  cursor: pointer;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: rgb(49, 49, 49);

  &__title {
    font-size: 3rem;
    font-weight: 200;
    margin-bottom: 2.5rem;
  }

  &__button {
    background-color: #4285f4 !important;
    color: #fff !important;
  }
}

@primary-color: #CF1515;