.question {
  color: rgb(210, 57, 57);
}

.greenText {
  color: green;
}
.container{
  margin-left: 1rem;

}
@primary-color: #CF1515;